<template>
    <div>
        <div class="subscription"
             :style="cssVars">
            <div class="subscription-sub">
                <div class="subscription-sub-inner">
                    <div class="cell-container">
                        <div class="cell-container-name">
                            Осталось
                        </div>
                        <div class="cell-container-value">
                            <div>{{Math.max(0, daysTillEndOfSubscription)}} </div>
                            <div>{{getText}}</div>
                            <div 
                                v-if="authorities.includes('ROLE_SUBSCRIBED_USER') 
                                && !authorities.includes('ROLE_ADMIN')
                                && mindControlledUser === null"
                                class="end-date"
                            >
                                до {{new Date(subscriptionEndDate).toLocaleDateString()}}, включительно
                                
                            </div>
                            <div 
                                v-if="mindControlledUser !== null
                                && Math.max(0, daysTillEndOfSubscription) > 0" 
                                class="end-date"
                            >
                                до {{new Date(subscriptionEndDate).toLocaleDateString()}}, включительно
                                
                            </div>
                            
                        </div>
                    </div>
                    <div>
                        <div class="cell-container-name">
                            Стоимость
                        </div>
                        <div class="cell-container-value">
                            54 BYN
                        </div>
                    </div>
                    <button
                        class="btn subscription-btn"
                        type="button"
                        @click="openSubscriptionModal"
                    >
                        Продлить подписку
                    </button>
                </div>
            </div>
        </div>
        <h2>История подписок</h2>
        <SubscriptionHistoryTable
                :subscriptions="generalSubscriptions"
        />
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex"
    import {getTranslateDays} from "@/utils/translateWord"
    import SubscriptionDateModal from '@/components/profilePage/SubscriptionDateModal'
    import {SubscriptionDateModalProperties} from '@/utils/modalsProperties'
    import SubscriptionHistoryTable from "@/components/profilePage/SubscriptionHistoryTable"

    export default {
        name: "Subscription",
        components: {
            SubscriptionHistoryTable
        },
        computed: {
            ...mapGetters('profile', ['daysTillEndOfSubscription', 'subscriptionEndDate']),
            ...mapGetters('impersonalization', ['mindControlledUser']),
            ...mapGetters('subscriptions', ['generalSubscriptions']),
            ...mapGetters('profile', ['profileId']),
            ...mapGetters('auth', ['authorities']),
            cssVars() {
                const color = this.daysTillEndOfSubscription <= 0 ?
                    "red" :
                    this.daysTillEndOfSubscription > 5 ?
                        "green" :
                        "#ffb645"

                return {
                    '--bg-color': color
                }
            },
            getText() {
                return getTranslateDays(Math.max(0, this.daysTillEndOfSubscription))
            },
        },
        methods: {
            ...mapActions('subscriptions', ['getSubscriptions']),
            openSubscriptionModal() {
                this.$modal.show(
                    SubscriptionDateModal,
                    null,
                    SubscriptionDateModalProperties
                )
            },
        },
        mounted() {
            this.getSubscriptions(this.profileId)
        }
    }
</script>

<style lang="less" scoped>
    @import '../../../assets/less/variables.less';

    .subscription {
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;
        &-sub {
            border: solid 1px var(--bg-color);
            border-radius: @br;
            height: 96px;

            &-inner {
                display: flex;
                justify-content: space-between;
                margin: 24px;

            }

            &-inner > div {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                height: 48px;
            }
        }
    }

    .subscription-btn {
        background-color: var(--bg-color);
        white-space: nowrap;
    }

    .cell-container {

        &-name {
            color: @text-color-light;
        }

        &-value {
            display: flex;
            justify-content: flex-start;
            gap: 5px;
            align-items: flex-end;
            margin-top: 5px;
            color: #37474F;
            font-size: 18px;

            .end-date{
                color: @text-color-light;
                font-size: 14px;
            }
        }

    }
</style>
